<template>
	<div>
		<div 
			v-if="showNavigation && isMobile"
			class="dim"
			@click="showNavigation = false">
			
		</div>
		
		<transition 
			v-if="showNavigation"
			name="slide-in"
			appear>
			
			<nav 
				class="
					flex flex-col bg-gray-darkest w-64
					fixed top-0 left-0 right-0 bottom-0
					md:static
				"
				style="z-index: 100;">
				
				<div class="flex-grow">
					<div 
						class="fixed top-0 left-0 flex m-4 w-12 h-12 items-center justify-center md:hidden"
						@click="showNavigation = false">
						
						<i class="fas fa-times text-white text-xl" />
					</div>
					
					<router-link 
						:to="{ name: 'portal-home', params: {accountId} }"
						class="h-48 flex items-center justify-center">
						
						<img src="/assets/logo.svg" />
					</router-link>
					
					<router-link 
						:to="{ name: 'portal-home', params: {accountId} }"
						class="item">
						
						<SvgIcon icon="overview" />
						
						Oversikt
					</router-link>
					
					<router-link 
						:to="{ 
							name: 'portal-users', 
							params: { 
								accountId: accountId,
							}
						}"
						class="item">
						
						<SvgIcon icon="access" />
						
						Brukere
					</router-link>
				</div>
				
				<div class="flex-none mb-4">
					<a 
						class="item flex-none"
						:class="showKudosGive ? 'text-white bg-black' : ''"
						@click="$emit('setShowKudosGive', !showKudosGive)">
						
						<SvgIcon icon="kudos" />
						
						<span v-if="showKudosGive">Skjul live-aktivitet</span>
						<span v-else>Vis live-aktivitet</span>
					</a>
					
					<div 
						@click="onSignOut()"
						class="item cursor-pointer">
						
						<SvgIcon icon="signOut" />
						
						Logg ut
					</div>
				</div>
			</nav>
		</transition>
		
		<div
			v-else
			class="fixed top-0 left-0 flex m-4 w-12 h-12 items-center justify-center"
			style="z-index: 100;"
			@click="showNavigation = true">
			
			<i class="fas fa-bars text-black text-xl" />
		</div>
	</div>
</template>


<script>
	import PortalMixins from '@/mixins/PortalMixins';

	export default {
		mixins: [
			PortalMixins,
		],
		
		props: { 
			showKudosGive: {
				type: Boolean,
				required: true,
			},
		},
		
		data(){
			return {
                showNavigation: true,
			}
		},
		
		methods: {
			async onSignOut(){
				if (! confirm('Er du sikker på at du vil logge ut av fellesbrukeren?')) return; 
				await this.signOut();
				
				this.$router.push({ name: 'portal-signin' });
				console.log('done signing out!');
			},
			
			/* onShowKudosGiveToggle(){
				this.showKudosGive = !this.showKudosGive;
				// this.$store.commit('setShowKudosGive', !this.showKudosGive);
			}, */
		},
	}
</script>

<style lang="scss" scoped>
    .dim {
        @apply opacity-25;
        @apply bg-black;
        // @apply bg-transparent;
        @apply fixed;
        @apply inset-0;
        z-index: 99;
    }
    
    nav {
        .item {
            @apply flex;
            @apply items-center;
            @apply mx-4;
            @apply mb-2;
            @apply p-2;
            @apply rounded-lg;
            @apply text-gray-lighter;
            
            svg {
                @apply w-5;
                @apply h-5;
                @apply mr-4;
                @apply ml-2;
            }
            
            &:hover:not(.router-link-active) {
                // @apply bg-gray-200;
            }
            
            &.router-link-active {
                @apply text-white;
                @apply bg-black;
            }
            
        }
    }
</style>
